import React, { Component } from 'react';
import './styles/success.css';
import './styles/successlaptop.css';
import './styles/successdesktop.css';

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <React.Fragment>
                <div id="myModal" className="modal fade">
                    <div className="modal-dialog modal-confirm">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center">
                                <div className="icon-box">
                                    <i className="material-icons">&#xE876;</i>
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            </div>
                            <div className="modal-body text-center">
                                <h4>Great!</h4>	
                                <p>Your request has been sent successfully.</p>
                                <a className="btn btn-success boton" href="/"><span>Cerrar</span> <i className="material-icons">&#xE5C8;</i></a>
                            </div>
                        </div>
                    </div>
                </div>     
            </React.Fragment>
        );
    }
}

export default Success;