import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/About';
import Success from './Success';

import Layout from './Layout';
// import NotFound from '../pages/NotFound';

function App(){
    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home} />
              
                    <Route exact path="/about" component={About} />

                    <Route exact path="/success" component={Success} />
        
                    {/* <Route component={NotFound}/> */}
                </Switch>
            </Layout>
            
                    
         
        </BrowserRouter>
    )
}

export default App;
