import React, { useState } from 'react';
// import Logo from '../assets/logo/logopng.png';
import './styles/Navbar.css';
import './styles/NavbarLaptop.css';
import './styles/NavbarDesktop.css';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => setIsOpen(!isOpen);

  return (

    

    <div className="container-fluid nav-page">


        <div className="adress-parent">
            
            <div className="adress-container">
                <span className="adress-info">Call 8AM-Midnight EST: 786-789-0442</span>
            </div>
        </div>
    
    {/* <hr> */}
    <div className="nav-options">
        
        <div className="logo">
            {/* <img src="https://nationalbusinesslending.org/wp-content/uploads/2017/09/National_Business-LOGO-e1504869086932.jpg" alt="">
            </img>             */}

            <h1 className="title-logo">Pristineloans</h1>
        </div>

            <ul className="navbar">
                <li><i className="fas fa-home fa-lg fa-fw"></i><a href="./">Home</a></li>
                <li><i className="fas fa-info-circle fa-lg fa-fw"></i><a href="./about">About Us</a></li>
                <li><i className="fas fa-search"></i></li>
            
            </ul>
    </div>

</div>
  );
}

export default Example;

